const data = [
    {
        img: "image/poodle-3-5-2022.webp",
        breed: "Labrador Retriever",
        pet_color: "Black",
        introduction: "Playing fetch",
        location: "New York",
        gender: "Male",
        category:"dog"
    },
    {
        img: "image/dog/IMG-20190508-WA0004.jpg",
        breed: "Afgan Hound",
        pet_color: "White",
        introduction: "Climbing trees",
        location: "Los Angeles",
        gender: "Female",
        category:"dog"
    },
    {
        img: "image/64866202406091628049910.jpeg",
        breed: "Jambo",
        pet_color: "Brown",
        introduction: "Chewing toys",
        location: "Chicago",
        gender: "Male",
        category:"dog"
    },
    {
        img: "image/265949202406091207136569.jpeg",
        breed: "Shih tzu",
        pet_color: "Golden",
        introduction: "Flying around",
        location: "Houston",
        gender: "Female",
        category:"dog"
    },
    {
        img: "image/265910202406090959365863.jpeg",
        breed: "A.J. Boxer's",
        pet_color: "Golden",
        introduction: "Running in the park",
        location: "San Francisco",
        gender: "Male",
        category:"dog"
    },
    {
        img: "image/265719202406081855225292.jpeg",
        breed: "Crysta",
        pet_color: "Cream",
        introduction: "Chasing laser pointers",
        location: "Seattle",
        gender: "Female",
        category:"dog"
    },{
        img: "image/203965202406071745396125.jpeg",
        breed: "Coco",
        pet_color: "Cream",
        introduction: "Chasing laser pointers",
        location: "Seattle",
        gender: "Female",
        category:"dog"
    },{
        img: "image/265719202406081855225292.jpeg",
        breed: "Crysta",
        pet_color: "Cream",
        introduction: "Chasing laser pointers",
        location: "Seattle",
        gender: "Female",
        category:"dog"
    },{
        img: "image/265719202406081855225292.jpeg",
        breed: "Crysta",
        pet_color: "Cream",
        introduction: "Chasing laser pointers",
        location: "Seattle",
        gender: "Female",
        category:"cat"
    },{
        img: "image/265719202406081855225292.jpeg",
        breed: "Crysta",
        pet_color: "Cream",
        introduction: "Chasing laser pointers",
        location: "Seattle",
        gender: "Female",
        category:"cat"
    },{
        img: "image/265719202406081855225292.jpeg",
        breed: "Crysta",
        pet_color: "Cream",
        introduction: "Chasing laser pointers",
        location: "Seattle",
        gender: "Female",
        category:"cat"
    },{
        img: "image/265719202406081855225292.jpeg",
        breed: "Crysta",
        pet_color: "Cream",
        introduction: "Chasing laser pointers",
        location: "Seattle",
        gender: "Female",
        category:"cat"
    },{
        img: "image/265719202406081855225292.jpeg",
        breed: "Crysta",
        pet_color: "Cream",
        introduction: "Chasing laser pointers",
        location: "Seattle",
        gender: "Female",
        category:"cat"
    },{
        img: "image/265719202406081855225292.jpeg",
        breed: "Crysta",
        pet_color: "Cream",
        introduction: "Chasing laser pointers",
        location: "Seattle",
        gender: "Female",
        category:"small-pet"
    },{
        img: "image/265719202406081855225292.jpeg",
        breed: "Crysta",
        pet_color: "Cream",
        introduction: "Chasing laser pointers",
        location: "Seattle",
        gender: "Female",
        category:"small-pet"
    },{
        img: "image/265719202406081855225292.jpeg",
        breed: "Crysta",
        pet_color: "Cream",
        introduction: "Chasing laser pointers",
        location: "Seattle",
        gender: "Female",
        category:"small-pet"
    },{
        img: "image/265719202406081855225292.jpeg",
        breed: "Crysta",
        pet_color: "Cream",
        introduction: "Chasing laser pointers",
        location: "Seattle",
        gender: "Female",
        category:"small-pet"
    },{
        img: "image/265719202406081855225292.jpeg",
        breed: "Crysta",
        pet_color: "Cream",
        introduction: "Chasing laser pointers",
        location: "Seattle",
        gender: "Female",
        category:"small-pet"
    },{
        img: "image/265719202406081855225292.jpeg",
        breed: "Crysta",
        pet_color: "Cream",
        introduction: "Chasing laser pointers",
        location: "Seattle",
        gender: "Female",
        category:"small-pet"
    },{
        img: "image/265719202406081855225292.jpeg",
        breed: "Crysta",
        pet_color: "Cream",
        introduction: "Chasing laser pointers",
        location: "Seattle",
        gender: "Female",
        category:"small-pet"
    }
];
export default data
