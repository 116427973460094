import React from 'react'
import Container from './Container'



export default function ExitingService() {
    return (
        <Container>
            <h1  className='flex linearcolor justify-center items-center'>Exciting Services For Your Pets</h1>
            <div className='grid gap-4 grid-cols-4'>
                <div className='w-full box  relative  h-[250px]'>
                <div className='absolute flex justify-end py-4 flex-col items-center w-full h-full mybgset top-0 left-0'>
                        <h1 className='text-[25px] font-bold text-[yellow]'>Dog Grooming</h1>
                        <button className='border-2 px-4 text-white border-white'>Done</button>
                    </div>
                <img className='w-full h-full' src="image/dog-grooming_2021.webp" alt="" />
                </div>
                <div className='w-full box col-span-2 h-[250px] relative '>
                <div className='absolute flex justify-end py-4 flex-col items-center w-full h-full mybgset top-0 left-0'>
                        <h1 className='text-[25px] font-bold text-[yellow]'>Dog Hostel</h1>
                        <button className='border-2 px-4 text-white border-white'>Done</button>
                    </div>
                <img className='w-full h-full' src="image/hero-mobile_23112022.webp" alt="" />
                </div>
                <div className='w-full box  relative h-[250px]  '>
                <div className='absolute flex justify-end py-4 flex-col items-center w-full h-full mybgset top-0 left-0'>
                        <h1 className='text-[25px] font-bold text-[yellow]'>Dog Training </h1>
                        <button className='border-2 px-4 text-white border-white'>Done</button>
                    </div>
                <img className='w-full h-full' src="image/dog-training-2021.webp" alt="" />
                </div>
            </div>
            <div className='mt-10 grid grid-cols-2'>
                <div className='w-full box  relative  h-[250px]'>
                <div className='absolute flex justify-end py-4 flex-col items-center w-full h-full mybgset top-0 left-0'>
                        <h1 className='text-[25px] font-bold text-[yellow]'>Mating Service</h1>
                        <button className='border-2 px-4 text-white border-white'>Done</button>
                    </div>
                <img className='w-full h-full' src="image//mating-services-2021.webp" alt="" />
                </div>
                <div className='w-full box  relative  h-[250px]'>
                <div className='absolute flex justify-end py-4 flex-col items-center w-full h-full mybgset top-0 left-0'>
                        <h1 className='text-[25px] font-bold text-[yellow]'>Dog Adoption</h1>
                        <button className='border-2 px-4 text-white border-white'>Done</button>
                    </div>
                <img className='w-full h-full' src="image/pet-adoption-2021.webp" alt="" />
                </div>
                
            </div>
        </Container >
    )
}
