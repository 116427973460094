
import React, { createContext } from 'react';
const MainContext = createContext()
const Context = (props) => {
    const handleWhatsAppClick = (props) => {
        const phoneNumber = '6377335576';
        const message = encodeURIComponent(`Hello  Meandboo , I am interested in your services  ${props.name}  and would like to get more  information,I would also like to know how I can reach out to your team. Please provide details on how to 'contact us`);

        const whatsappURL = `https://wa.me/${phoneNumber}?text=${message}`;
        window.open(whatsappURL, '_blank');
    };
    
  
    return (
        <MainContext.Provider value={{ handleWhatsAppClick }}>
            {props.children}
        </MainContext.Provider>
    );
}
export default Context;
export { MainContext }
