import React from 'react'
import { MdMarkEmailUnread } from "react-icons/md";
import { FaMobileAlt } from "react-icons/fa";
import { LuPin } from "react-icons/lu";

export default function Contectus() {
    <iframe src="" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    const items=[
        {
            icon:<LuPin />,
            name:"Head Office",
            a:"Plot Shop No 4,5 Jagat Vihar, Near PNB Bank Jagatpura, Jaipur, Rajasthan",
            url:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d227748.43601872612!2d75.62574101228621!3d26.885421393295044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396c4adf4c57e281%3A0xce1c63a0cf22e09!2sJaipur%2C%20Rajasthan!5e0!3m2!1sen!2sin!4v1718103038483!5m2!1sen!2sin"
        },
        {
            icon:<LuPin />,
            name:"Corporate Office",
            a:"Plot Shop No 4,5 Jagat Vihar, Near PNB Bank Jagatpura, Jaipur, Rajasthan",
            url:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d227748.43601872612!2d75.62574101228621!3d26.885421393295044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396c4adf4c57e281%3A0xce1c63a0cf22e09!2sJaipur%2C%20Rajasthan!5e0!3m2!1sen!2sin!4v1718103038483!5m2!1sen!2sin"
        },
        {
            icon:<FaMobileAlt />,
            name:"Email",
            a:"Meandboo@gmail.com",
            url:"Meandboo@gmail.com"
        },
        
        {
            icon:<MdMarkEmailUnread />,
            name:"Phone",
            a:"Landline: +91-6377335576 / +91-6377335576",
            url: "tel:+916377335576"
        },
        

    ]
  return (
    <div className='max-w-[1200px] mt-20 mx-auto'>
      
        <div className='grid my-4 lg:grid-cols-4 md:grid-cols-4 grid-cols-1  lg:gap-6 gap-10 px-8 py-8'>
               {
                items.map(
                    (d,i)=>{
                        return(
                            <div className='w-full   border-separate p-2 '>
                            <div className='flex items-center justify-center text-[40px] text-blue-950'>{d.icon}
                            </div>
                            <div className='flex items-center justify-center text-[20px] md:text-[25px] text-[#090909] font-medium pl-[15px] pt-[20px]'><h1>{d.name}</h1></div>
        
                            <div className='flex items-center text-center justify-center text-[16px] text-[#45454c]  pl-[15px] pt-[20px]'>
                                <a href={d.url}>{d.a}</a>
                            </div>
                        </div>
                        )
                    }
                )
               }
               </div>
    </div>
  )
}
