import React from 'react'
import Hostel from '../component/Hostel'

export default function Hostal() {
  return (
    <>
    <Hostel/>
    </>
  )
}
