import React from 'react';

const Container = (props) => {
    return (
        <div className={`${props.fluid == true ? 'w-full' : 'max-w-[1200px]'} mx-auto ${props.extraclass ?? ''}`}>
            {props.children}
        </div>
    );
}

export default Container;
