import React from 'react'
import  ContactsCCom  from '../component/ContactsCCom' 
import  Contectus  from '../component/Contectus' 

export default function Contact() {
  return (
    <div>
        <Contectus/>
            <ContactsCCom/>
    </div>
  )
}
