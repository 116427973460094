
"use client";

import { Carousel } from "flowbite-react";

export default function Carosel() {
  return (
    <div className="h-56 sm:h-64 xl:h-[500px] 2xl:h-[500px]">
      <Carousel slide={false}>
        <img src="image/dog/1.jpeg" alt="..." />
        <img src="image/dog/2.jpeg" alt="..." />
        <img src="image/dog/4.jpeg" alt="..." />
      </Carousel>
    </div>
  );
}
