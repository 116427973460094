import React from 'react'
import Container from './Container'

export default function MMp() {
  const item = [
    {
      img: 'image/Healthy_Pet.png',
      head: "Healthy Pet",
      par: "Being pet lovers our self, we understand the importance of a pet’s health. All our puppies are at least eight weeks old when they are sent to you. Before your bundle of joy reaches you, he is required to undergo an extensive health checkup by a licensed veterinarian."
    },
    {
      img: 'image/Vaccinated.png',
      head: "Vaccinated & Insured Pet",
      par: "Being pet lovers our self, we understand the importance of a pet’s health. All our puppies are at least eight weeks old when they are sent to you. Before your bundle of joy reaches you, he is required to undergo an extensive health checkup by a licensed veterinarian."
    },
    {
      img: 'image/Responsible.png',
      head: "Responsible Breeders",
      par: "Being pet lovers our self, we understand the importance of a pet’s health. All our puppies are at least eight weeks old when they are sent to you. Before your bundle of joy reaches you, he is required to undergo an extensive health checkup by a licensed veterinarian."
    },
    {
      img: 'image/Processw.png',
      head: "Easy and Hassle-free Process",
      par: "Being pet lovers our self, we understand the importance of a pet’s health. All our puppies are at least eight weeks old when they are sent to you. Before your bundle of joy reaches you, he is required to undergo an extensive health checkup by a licensed veterinarian."
    },
    {
      img: 'image/experts.png',
      head: "Expert Pet Guidance",
      par: "Being pet lovers our self, we understand the importance of a pet’s health. All our puppies are at least eight weeks old when they are sent to you. Before your bundle of joy reaches you, he is required to undergo an extensive health checkup by a licensed veterinarian."
    },
    {
      img: 'image//familya.png',
      head: "Happy Pet Parenting",
      par: "Being pet lovers our self, we understand the importance of a pet’s health. All our puppies are at least eight weeks old when they are sent to you. Before your bundle of joy reaches you, he is required to undergo an extensive health checkup by a licensed veterinarian."
    }
  ];
  return (
    <Container extraclass="flex justify-center items-center gap-10 flex-col">
      <div>
        <h1 className='text-[#195070] text-center text-[34px]'>Why Meandboo? </h1>
        <p>Looking for a furry companion? Know why Meandboo is the perfect option for you.</p>
      </div>
      <div className='grid lg:grid-cols-3 sm"grid-col-2 grid-cols-1 gap-10'>
        {
          item.map((data, index) => {
            return (
              <div className='p-6 mx-10 mb-4 sm:mx-0 ' style={{ boxShadow: "1px 2px 37px 4px rgb(120 117 117 / 13%)" }}>
                <img src={data.img} alt="" />
                <h3 className='my-4'>{data.head}</h3>
                <p>Being pet lovers our self, we understand the importance of a pet’s health. All our puppies are at least eight weeks old when they are sent to you. Before your bundle of joy reaches you, he is required to undergo an extensive health checkup by a licensed veterinarian.</p>
              </div>
            )
          })
        }

      </div>
    </Container>

  )
}
