import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Main from './pages/Main';
import Home from './pages/Home';
import Listing from './component/Listing';
import Contact from './pages/Contact'
import Hostal from './pages/Hostal';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    children: [
      {
        path: "/",
        element: <Home />
      },{
        path: "/contact",
        element: <Contact/>
      },
      {
        path: "/cats",
        element: <Listing type="cat" />
      },
      {
        path: "/dogs",
        element: <Listing type="dog" />
      },
      {
        path: "/small-pet",
        element: <Listing type="small-pet" />
      }, {
        path: "/hostel",
        element: <Hostal/>
      }
    ]
  }
]);

export default function App() {
  return (
    <RouterProvider router={router} />
  );
}
