import React, { useState } from 'react';
import Container from './Container';
import { Link } from 'react-router-dom';

const Grandmenu = () => {
    const [isActive, setIsActive] = useState(false);
    const handleMenuClick = () => {
        setIsActive(!isActive);
    };

    return (
        <Container fluid={true}>
            <header className='text-[10px]' style={{ zIndex: '1000' }}>
                <div className="container ">
                    <nav className={isActive ? 'active' : ''}>
                        <div>
                            <div className="menu-icons" onClick={handleMenuClick}>
                                <i className="fas text-black text-3xl fa-bars"></i>
                                <i className="fas text-black text-3xl fa-times"></i>
                            </div>
                            <Link to='/' className='my-4'>
                                <img src="image/dog/logo.jpeg" className="md:cursor-pointer w-18 h-16" alt="" />
                            </Link>
                        </div>
                        <ul class="nav-list">
                            <li>
                                <Link className='text-[10px] ' to=''>Pets<i class="fas  fa-caret-down"></i></Link>
                                <ul style={{ width: '12rem' }} class="sub-menu p-2">
                                    <li>
                                        <Link onClick={handleMenuClick} to='/dogs'>Dogs</Link>
                                    </li>
                                    <li>
                                        <Link onClick={handleMenuClick} to='/cats'>Cats</Link>
                                    </li>
                                    <li>
                                        <Link onClick={handleMenuClick} to='/small-pet'>Small  Pet</Link>
                                    </li>

                                </ul>
                            </li>
                            <li>
                                <Link className='text-[10px] ' to=''>Pets Service<i class="fas  fa-caret-down"></i></Link>
                                <ul style={{ width: '12rem' }} class="sub-menu p-2">
                                    <li>
                                        <Link onClick={handleMenuClick} to='/dogs'>Pet Adoption</Link>
                                    </li>
                                    <li>
                                        <Link onClick={handleMenuClick} to='/hostel'>Pet Hostel</Link>
                                    </li>
                                    <li>
                                        <Link onClick={handleMenuClick} to='/small-pet'>Pet Grooming  </Link>
                                    </li><li>
                                        <Link onClick={handleMenuClick} to='/small-pet'>Pet Training  </Link>
                                    </li>
                                    <li>
                                        <Link onClick={handleMenuClick} to='/small-pet'> Pet Walking   </Link>
                                    </li>

                                </ul>
                            </li>
                            <li>
                                <Link onClick={handleMenuClick} className='text-[10px]' to='/contact'>Contact</Link>
                            </li>
                            <li>
                                <Link onClick={handleMenuClick} className='text-[10px]' to='/contact'>Foods</Link>
                            </li>
                            <li>
                                <Link onClick={handleMenuClick} className='text-[10px]' to='/contact'>Breeds</Link>
                            </li>

                        </ul>
                    </nav>
                </div>
            </header>
        </Container>
    );
}
export default Grandmenu;










