import React from 'react'
import Available from '../component/Available'
import MMp from '../component/MMp'
import ExitingService from '../component/ExitingService'
import Join from '../component/Join'
import Listing from '../component/Listing'
import Carosel from '../component/Carosel'
import Footer from '../component/Footer'


export default function Home() {
  return (
    <>
      <Carosel />
      <Available />
      <MMp />
      <ExitingService />
      <Join />
      <Listing />
  



    </>
  )
}
