import React from 'react'
import Grandmenu from '../component/Grandmenu'
import { Outlet } from 'react-router-dom'
import Footer from '../component/Footer'

export default function Main() {
  return (
    <>
    <Grandmenu/>
    <Outlet/>
    <Footer />
    </>
  )
}


