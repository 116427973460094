const ListingData=[
    {
Head:"Dogs For Adoption",
 category:"cat",
fullDetails:"Planning to Adopt a Pet? Your come to the right place! We provide healthy puppies and dogs for adoption in India. Find animal shelters and pet adoption centers in India near you. Urgent Need for pet adopters near me Pets for adoption in India - Find puppies and dogs for adoption in India. Pets are available for adoption through free animal rescue centers near you. Mr n Mrs Pet with the absolute responsibility of completing your family with your dream companion and giving pets a loving home. Let s find the perfect match for you! We ensure that you Find puppies that are healthy and happy. We are also a one-stop online pet shop near you for all that your dog solution needs We are proficient in shipping and can arrange transportation to get your puppy safely to your home. We take pride and joy in raising high-quality dogs in India. Call us today at 7597972222 and we d love to answer any of your pet-related queries"
    },
    {
        Head:"Dogs For Sale",
         category:"dog",
        fullDetails:"Buy Health-certified dogs and puppies online in India for sale, Available Dog breeds like Labrador, German Shepherd, Golden Retriever, Shih Tzu, Siberian Husky, Poodle, and Maltipoo. The most popular species are available in India, Labrador Retriever (Rs. 12 K - 80 K ), Poodle (Rs. 1.10 Lakh - 2.90 Lakh), Maltipoo (Rs. 1.05 Lakh - 2.10 Lakh), Siberian Husky (Rs. 25 K - 1 Lakh), Shih Tzu (Rs. 20 K - 80 K). Newborn puppy prices start from Rs 12,000 in India. To know more about puppy prices, photos, videos, reviews, and other details, please select your desired breed from the list below. Also, 7597972222, call now and check the dog's valuation on Mr n Mrs Pet."
            },

            {
                Head:"Cat For Sale",
                 category:"catss",
                fullDetails:"Buy Health-certified cats and kittens in India for sale, Available Cat breeds like Persian, Bengal, Himalayan, Maine Coon, Ragdoll, British Shorthair, British Longhair, and Calico Kittens. The most popular species are India Persian (Rs. 12 K - 35 K ), Bengal (Rs. 90 K - 2.50 Lakh), Ragdoll (Rs. 45 K - 95 K), British Shorthair (Rs. 1.20 Lakh - 2.90 Lakh), British Longhair (Rs. 70 K - 2.50 Lakh). Newborn kitten prices start from Rs 12,000 in India. To know more about kitten prices, photos, videos, reviews, and other details, please select your desired breed from the list below. Also, 7597972222, call now and check the cat's valuation on Mr n Mrs Pet."
                    },
                    {
                        Head:"Small Pets For Sale",
                         category:"dogsss",
                        fullDetails:"Buy Health-certified small pets in India for sale, Available breeds like Guinea Pigs, Rabbits, Hamsters, and Bunnies. The most popular species are India Guinea Pigs (Rs. 2 K - 15 K ), Hamsters (Rs. 2 K - 8 K), and Bunnies (Rs. 10 K - 35 K). Newborn small pet prices start from Rs 1,000 in India. To know more about small pet prices, photos, videos, reviews, and other details, please select your desired breed from the list below. Also, 7597972222, call now and check the small pet's valuation on Mr n Mrs Pet."
                            },
]

export default ListingData