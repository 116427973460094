import React,{useContext} from 'react'
import Container from './Container';
import {MainContext} from '../context/Context';


export default function Hostel() {
  const {handleWhatsAppClick}=useContext(MainContext)

    const data = [{
        img: "image/203965202406071745396125.jpeg",
    }, {
        img: "image/265910202406090959365863.jpeg",
    }, {
        img: "image/203965202406071745396125.jpeg",
    }
    ]
    return (
        <Container extraclass='my-20'>
            <div className='grid gap-4 md:grid-cols-3 grid-cols-1 '>
                {
                    data.map((d, i) => {
                        return (
                            <div className='px-6'>
                                <img   className='w-full' src={d.img} alt="" />
                                <div className='flex my-6 justify-between'>
                                <button className='bg-[#2b98bb] w-[40%] text-[12px]  py-2 rounded-lg text-white'  onClick={handleWhatsAppClick}>Book Now</button>
                                <button className='bg-[#2b98bb] w-[40%] px-10 text-[12px] py-2 rounded-lg text-white'  onClick={handleWhatsAppClick}>Whatsapp</button>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className='px-4'>
                <h2 className='text-[40px] mb-4 text-center'>Book Dog Hostel & Pet Boarding in India at Best Prices</h2>
               <div className='flex flex-col gap-6 text-justify'>
               <p>
                Book a Dog Hostel and Dog Boarding in India at Best Prices on , Whether You live in Rajasthan, Delhi, Punjab, Uttar Pradesh, Gujarat, Maharastra, Telangana, Book a Dog Boarding Service near you.
                </p>
                <p>
                When you have to go away or work long hours, don’t worry about leaving your pet alone at home. There are a variety of dog hostel packages that are just right for your pet because your furr baby deserves the best. Book your dog hostel online today and be worry-free; We provide a home-based dog hostel in India.
                </p>
                <p>
                Meandboo provides the best Dog daycare center in India. We connect the best pet-friendly dog hostel & pet sitters in India, who offer insured and secured pet care services. Book the best Pet sitters & dog boarding services near you.
                </p>
                <p>
                We have the best network of pet sitters and Dog daycare centers near you that are safe, hygienic, and administered by compassionate and trained associates who have an imminent love for pets.
                </p>
                <p>
                Your pup will feel at home and loved at our Pet Boarding Facility, just like family. Based on your furr baby requirements Meandboo, we care for your dog’s personal needs.
                </p>
               </div>
            </div>
        </Container>
    )
}
