import React from 'react'
import Container from './Container'

export default function Join() {
    const item=[
        {
            head:"Join as a Breeder"
        },
         {
            head:"Join as a Breeder"
        }, 
        {
            head:"Join as a Breeder"
        }, 
        {
            head:"Join as a Breeder"
        }
        , 
        {
            head:"Join as a Breeder"
        }
        , 
        {
            head:"Join as a Breeder"
        }
    ]
  return (
    <Container>
        <h1 className='linearcolor text-center my-10'>Join Us</h1>
        <div className=' grid gap-4 grid-cols-3'>
            {
                item.map((d,i)=>{
                    return (
                        <div style={{boxShadow:"rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px"}} className='bg-white linearcolor py-2 flex justify-center items-center'>{d.head}</div>
                    )
                })
            }
<div className='py-10 flex justify-center items-center'>

</div>
        </div>
    </Container>
  )
}
