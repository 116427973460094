import React, { useContext, useState } from 'react';
import { IoMaleFemaleSharp } from 'react-icons/io5';
import { CiLocationOn } from 'react-icons/ci';
import data from './Data';
import listingHeadData from './ListingHead';
import {MainContext} from '../context/Context'

export default function Listing({ type }) {
  const [expanded, setExpanded] = useState(null);
  const {handleWhatsAppClick}=useContext(MainContext)


  const handleToggle = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  const filteredData = data.filter(item => item.category === type);
  const filterDetails = listingHeadData.filter(item => item.category === type);
  
  return (
    <div className='my-[120px]'>
      {filterDetails.map((d, i) => (
        <div key={i}>
          <h1 className='text-[25px] px-4 font-bold'>{d.Head}</h1>
          <p className='text-[16px] text-[#000] my-4 px-4'>
            {expanded === i ? d.fullDetails : `${d.fullDetails.slice(0, 300)}...`}
            {d.fullDetails.length > 20 && (
              <button onClick={() => handleToggle(i)} className='text-blue-500'>
                {expanded === i ? 'View Less' : 'View More'}
              </button>
            )}
          </p>
        </div>
      ))}
      <div className='grid px-4 gap-6 md:grid-cols-2 lg:grid-cols-4'>
        {filteredData.map((item, index) => (
          <div key={index} className='border p-2 myboxshadow border-red-100'>
            <img className='w-full round-set h-[247px]' src={item.img} alt={item.breed} />
            <p className='text-[14px] px-4 text-black'>{item.breed}</p>
            <h5 className='text-[#2b98bb] px-4 py-1'>{item.pet_color}</h5>
            <p className='text-[14px] px-4 text-black'>{item.introduction}</p>
            <div className='flex mx-10 border border-red-50 justify-between my-2'>
              <div className='flex justify-center items-center flex-col'>
                <CiLocationOn />
                {item.location}
              </div>
              <div className='flex justify-center items-center flex-col'>
                <IoMaleFemaleSharp />
                {item.gender}
              </div>
            </div>
            <div className='flex justify-around my-2'>
              <button className='bg-[#2b98bb] px-4 text-[12px] py-2 rounded-lg text-white'  onClick={handleWhatsAppClick}>Call</button>
              <button className='bg-[#2b98bb] px-4 text-[12px] py-2 rounded-lg text-white'  onClick={handleWhatsAppClick}>Whatsapp</button>
              <button className='bg-[#2b98bb] px-4 text-[12px] py-2 rounded-lg text-white'>Details</button>
            </div>
            <div className='px-2'>
              <button onClick={()=>{handleWhatsAppClick(item.breed)}} className='w-full p-2 text-white text-[12px] rounded-lg bg-[#2b98bb] mb-4'>Book Now</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}