import React from 'react';
import Container from './Container';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default function Available() {
  const item = [
    {
      img: 'image/poodle-3-5-2022.webp',
      head: "Dog"
    },
    {
      img: 'image/dog2.webp',
      head: "Dog"
    },
    {
      img: 'image/dog2.webp',
      head: "Dog"
    },
    {
      img: 'image/dog2.webp',
      head: "Dog"
    },
    {
      img: 'image/dog2.webp',
      head: "Dog"
    }
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  return (
    <Container fluid={true} extraclass="bg-[#018f98]  py-[10px] text-[white]">
      <div className='px-10 max-w-[1200px] mx-auto'>
       <div className='py-5 flex justify-center items-center flex-col'>
       <h1 className=' text-[32px] color-white'>Available Pets</h1>
       <p>Explore and choose your perfect pet</p>
       </div>
        <Carousel responsive={responsive}>
          {item.map((data, index) => (
            <div key={index} className='px-2'>
              <img className='w-full h-[220px]' src={data.img} alt="" />
              <h4 className='bg-[#008080] flex justify-center items-center py-[1rem]'>{data.head}</h4>
            </div>
          ))}
        </Carousel>
      </div>
      <div className='flex justify-center items-center my-10'>
      <button className='text-[16px] border-2 border-white p-2'>View More Puppies</button>

      </div>
    </Container>
  );
}
